import axios, { type AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { HandledError } from '@/errorHandler';
import { Tool } from '@/models/tool/Tool';
import { i18n } from '@/plugins/vue-i18n';
import router, { loginPageWithRedirect } from '@/router';
import { getConfig, postRefresh } from '@/services/Auth';
import { isWorkerCall, LOGOUT, PENDING, REFRESH, RESUME, toExpiryDate, type WorkerCall } from '@/services/SessionRefreshingWorker';
import { useAuthStore } from '@/stores/auth';
import { assertIsDefined } from '@/utils/assertions';
import { ACCESS_TOKEN, EXPIRY_DATE, REFRESH_TOKEN } from '@/utils/auth';
import { metas } from '@/utils/navigation';
import type { Config } from '@/models/Auth';

const PROJECT_ID = 'projectId';
const getLocalStorageProjectId = () => window.localStorage.getItem(PROJECT_ID);

const SELECTED_TOOL = 'selectedTool';
const getLocalStorageSelectedTool = () => {
  const defaultTool = Tool.enum.EXACT;
  const local = window.localStorage.getItem(SELECTED_TOOL);
  const { tool, error } = Tool.parse(local);
  return error ? defaultTool : tool;
};

let pendingPromiseResolver: ((value: void | PromiseLike<void>) => void) | null = null;
let sessionRefreshingWorker: SharedWorker | null = null;

export type State = {
  /** Fulfilled once the initial page load is completed */
  initPromise: Promise<void>;
  /** While the SessionWorker is refreshing the token. If refreshing is finished, this gets removed again */
  pendingPromise: Promise<void> | null;
  config: Config | null;
  apiClientInstance: AxiosInstance | null;
  initComplete: boolean;
  accessToken: string | null;
  selectedProjectId: string | null;
  selectedTool: Tool;
};

let initPromiseResolver: ((value: void | PromiseLike<void>) => void) | null = null;
const initPromise = new Promise<void>(resolve => {
  initPromiseResolver = resolve;
});

export const useSystemStore = defineStore('system', {
  state: (): State => ({
    initPromise: initPromise,
    pendingPromise: null,
    config: null,
    apiClientInstance: null,
    initComplete: false,
    accessToken: null,
    selectedProjectId: getLocalStorageProjectId(),
    selectedTool: getLocalStorageSelectedTool(),
  }),

  getters: {
    cognitoUrl: state => {
      assertIsDefined(state.config);
      return state.config.cognitoUrl;
    },
    clientId: state => {
      assertIsDefined(state.config);
      return state.config.clientId;
    },
    externalLinks: state => {
      return state.config?.externalLinks ?? {};
    },
  },

  actions: {
    async init() {
      const authStore = useAuthStore();

      if (window.SharedWorker) {
        sessionRefreshingWorker = new SharedWorker(new URL('@/services/SessionRefreshingWorker', import.meta.url), {
          type: 'module',
        });
        sessionRefreshingWorker.port.onmessage = e => {
          if (!isWorkerCall(e.data)) return;

          switch (e.data.type) {
            case LOGOUT:
              if (!authStore.isLoggedIn) return;

              this.resetAll();

              ElMessage.warning({
                message: `${i18n.t('common.sessionExpired')} <a href="javascript:window.location.reload(true)">${i18n.t('common.reload')}</a>`,
                dangerouslyUseHTMLString: true,
                duration: 0,
              });
              break;

            case PENDING:
              this.pendingPromise = new Promise(resolve => {
                pendingPromiseResolver = resolve;
              });
              break;

            case REFRESH:
              this.persistAccessToken(e.data.accessToken, e.data.refreshToken, e.data.expiryDate);
              this.setApiClientHeaders();
              pendingPromiseResolver?.();
              this.pendingPromise = null;
              pendingPromiseResolver = null;
              break;
          }
        };
      }

      const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
      let accessToken = window.localStorage.getItem(ACCESS_TOKEN);
      let expiryDate = window.localStorage.getItem(EXPIRY_DATE);

      try {
        this.config = await getConfig();

        if (!accessToken || !refreshToken || !expiryDate) {
          throw new Error('Not logged in');
        }

        if (dayjs().isAfter(expiryDate)) {
          // is expired
          const refreshResponse = await postRefresh(refreshToken);
          accessToken = refreshResponse.access_token;
          expiryDate = toExpiryDate(refreshResponse.expires_in);
        }

        authStore.parseAccessToken(accessToken);

        this.persistAccessToken(accessToken, refreshToken, expiryDate);
        this.setApiClientHeaders();

        this.callWorker({ type: RESUME, accessToken, refreshToken, expiryDate, cognitoUrl: this.cognitoUrl, clientId: this.clientId });

        initPromiseResolver?.();
        this.initComplete = true;
      } catch (error) {
        this.cleanUserSettings();
        initPromiseResolver?.();
        this.initComplete = true;
      }
    },

    getApiClientInstance() {
      if (this.apiClientInstance === null) {
        this.apiClientInstance = axios.create({
          baseURL: this.config?.apiUrl,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          formSerializer: {
            indexes: true,
          },
          paramsSerializer: {
            indexes: null,
          },
        });

        this.apiClientInstance.interceptors.response.use(
          response => response,
          error => {
            if (error.response?.status === 401) {
              this.resetAll();
              error = new HandledError(error);
              ElMessage.error(i18n.t('common.sessionExpired'));
              router.push(loginPageWithRedirect());
            }

            return Promise.reject(error);
          }
        );
      }
      return this.apiClientInstance;
    },

    persistAccessToken(accessToken: string, refreshToken: string, expiryDate: string) {
      this.accessToken = accessToken;
      window.localStorage.setItem(ACCESS_TOKEN, accessToken);
      window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
      window.localStorage.setItem(EXPIRY_DATE, expiryDate);
    },

    persistSelectedProjectId(projectId: string) {
      window.localStorage.setItem(PROJECT_ID, projectId);
    },

    persistSelectedTool(tool: string) {
      window.localStorage.setItem(SELECTED_TOOL, tool);
    },

    receiveLocalSelectedProjectId(): string | null {
      const localProjectId = getLocalStorageProjectId();

      const authStore = useAuthStore();
      const isInvalidCachedProjectId = !authStore.availableProjectIds.find(p => p === localProjectId);
      if (isInvalidCachedProjectId) {
        const firstProjectIdFromToken = authStore.availableProjectIds[0];
        this.persistSelectedProjectId(firstProjectIdFromToken);
        return firstProjectIdFromToken;
      }

      return localProjectId;
    },

    setApiClientHeaders() {
      const instance = this.getApiClientInstance();
      instance.defaults.headers.common.Authorization = this.accessToken ? 'Bearer ' + this.accessToken : undefined;
      instance.defaults.headers.common['X-PROJECT-ID'] = this.selectedProjectId;
    },

    setProjectId(projectId: string) {
      if (this.selectedProjectId === projectId) return;
      const authStore = useAuthStore();
      if (!authStore.availableProjectIds.find(p => p === projectId)) {
        throw new Error(`Invalid project-id: ${projectId}`);
      }

      this.selectedProjectId = projectId;
      this.persistSelectedProjectId(projectId);
      this.setApiClientHeaders();
      const routeName = router.currentRoute.value.name ?? '';
      if (metas[routeName]?.hasProjectScope) {
        router.push({
          name: routeName,
          params: { ...router.currentRoute.value.params, projectId },
          query: router.currentRoute.value.query,
        });
      }
    },

    setSelectedTool(tool: Tool) {
      if (this.selectedTool === tool) return;
      this.selectedTool = tool;
      this.persistSelectedTool(tool);
    },

    cleanUserSettings() {
      window.localStorage.removeItem(ACCESS_TOKEN);
      window.localStorage.removeItem(REFRESH_TOKEN);
      window.localStorage.removeItem(EXPIRY_DATE);
      if (this.apiClientInstance) {
        this.apiClientInstance.defaults.headers.common.Authorization = undefined;
        this.apiClientInstance.defaults.headers.common['X-PROJECT-ID'] = undefined;
      }
    },

    callWorker(payload: WorkerCall) {
      sessionRefreshingWorker?.port.postMessage(payload);
    },

    logout() {
      this.callWorker({ type: LOGOUT });
      this.resetAll();
    },

    resetAll() {
      this.cleanUserSettings();
      const authStore = useAuthStore();
      authStore.$reset();
      this.reset();
    },

    reset() {
      const initPromiseBck = this.initPromise;
      const initCompleteBck = this.initComplete;
      const configBck = this.config;
      this.$reset();
      this.initPromise = initPromiseBck;
      this.initComplete = initCompleteBck;
      this.config = configBck;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSystemStore, import.meta.hot));
}
