<template>
  <RegionSelector />

  <SidebarLinkList :items="navItemsPrimaryTestportal" />

  <div v-for="(items, category) in navItemsCategoriesTestportal" :key="category">
    {{ $t(`navigation.${category}`) }}
    <SidebarLinkList :items />
  </div>

  <SidebarExpander />
</template>

<script setup lang="ts">
import RegionSelector from '@/components/RegionSelector.vue';
import SidebarExpander from '@/components/UI/SidebarExpander.vue';
import useNavigation from '@/composables/useNavigation';
import SidebarLinkList from './SidebarLinkList.vue';

const { navItemsPrimaryTestportal, navItemsCategoriesTestportal } = useNavigation();
</script>
