<template>
  <aside v-if="isLoggedIn" class="sidebar" :class="{ expanded: isSidebarExpanded }">
    <TestportalSidebar v-if="system.selectedTool === Tool.enum.TESTPORTAL" />
    <nav v-else>
      <ProjectSelector />

      <div class="divider" />

      <SidebarLinkList :items="navItemsPrimaryFlat" />

      <div class="divider" />

      <SidebarLinkList :items="navItemsSecondary" />

      <div class="divider" style="margin-top: auto" />

      <SidebarExpander />
    </nav>
  </aside>
  <div v-else class="sidebar" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import ProjectSelector from '@/components/ProjectSelector.vue';
import SidebarExpander from '@/components/UI/SidebarExpander.vue';
import SidebarLinkList from '@/components/UI/SidebarLinkList.vue';
import TestportalSidebar from '@/components/UI/TestportalSidebar.vue';
import { isSidebarExpanded } from '@/composables/useLayout';
import useNavigation from '@/composables/useNavigation';
import { Tool } from '@/models/tool/Tool';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';

const system = useSystemStore();

const { navItemsPrimaryFlat, navItemsSecondary } = useNavigation();

const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);
</script>

<style lang="scss" scoped>
@use '@/assets/theme/variables';

.sidebar {
  position: fixed;
  z-index: 6;

  width: variables.$sidebar-mobile-width;
  height: calc(100% - variables.$topbar-height);
  background-color: var(--color-background);

  .spacer {
    width: variables.$sidebar-mobile-width;
  }

  nav {
    display: flex;
    flex-direction: column;

    width: variables.$sidebar-mobile-width;
    height: 100%;
    overflow-y: auto;
    background-color: var(--color-background);

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .divider {
      height: 1px;
      align-self: stretch;
      background: var(--Primary-hover-light, #e2deff);
    }
  }

  &.expanded {
    width: variables.$sidebar-width;

    .spacer {
      width: variables.$sidebar-width;
    }

    nav {
      width: variables.$sidebar-width;
    }
  }
}
</style>
