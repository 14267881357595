<template>
  <div class="header-wrapper">
    <header class="header">
      <nav>
        <ToolSelector />

        <div class="divider-v" />

        <DevSwitchMockApi v-if="hasMsw" />

        <div class="menu-right">
          <div class="divider-v" />

          <ElMenu
            mode="horizontal"
            :ellipsis="false"
            :default-active="activeName"
            router
            :default-openeds="activeName ? [activeName] : undefined"
            @select="onSelect"
          >
            <ElMenuItem v-if="isLoggedIn" index="color" :route="{}">
              <template #title>
                <Btn
                  unstyled
                  :svg-icon="isDark ? IconModeLight : IconModeDark"
                  only
                  tabindex="-1"
                  :title="toggleTranslation"
                  placement="bottom"
                  :show-after="500"
                >
                  <span class="sr-only">{{ toggleTranslation }}</span>
                </Btn>
              </template>
            </ElMenuItem>

            <ElSubMenu v-if="Object.keys(externalLinks).length" index="GRID" class="no-arrow">
              <template #title>
                <IconGrid />
              </template>

              <ElMenuItem v-for="(url, key) in externalLinks" :key :index="key" :route="{}">
                <template #title>
                  <a :href="url" target="_blank" class="external">{{ $to(key, 'navigation.external.') }}</a>
                </template>
              </ElMenuItem>
            </ElSubMenu>

            <ElSubMenu v-if="isLoggedIn" index="USER" class="no-arrow">
              <template #title>
                <IconUser />
                <span class="sr-only">{{ $t('navigation.USER') }}</span>
              </template>

              <ElMenuItemGroup>
                <template #title>
                  <FontAwesomeIcon :icon="faUser" transform="left-8" />
                  {{ fullName }}
                </template>

                <ElMenuItem v-for="item in visibleUserNavigationItems" :key="item" :index="item" :route="{ name: item }">
                  <template #title>{{ $to(item, 'navigation.') }}</template>
                </ElMenuItem>
              </ElMenuItemGroup>
            </ElSubMenu>
          </ElMenu>
        </div>
      </nav>
    </header>
  </div>
</template>

<script setup lang="ts">
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useDark, useToggle } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Btn from '@/components/Btn.vue';
import ToolSelector from '@/components/ToolSelector.vue';
import IconGrid from '@/components/UI/Icons/Grid.vue';
import IconModeDark from '@/components/UI/Icons/ModeDark.vue';
import IconModeLight from '@/components/UI/Icons/ModeLight.vue';
import IconUser from '@/components/UI/Icons/User.vue';
import useNavigation from '@/composables/useNavigation';
import { i18n } from '@/plugins/vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { visibleUserNavigationItems } from '@/utils/navigation';
import DevSwitchMockApi from './DevSwitchMockApi.vue';

const hasMsw = import.meta.env.DEV || import.meta.env.MODE === 'msw';

const authStore = useAuthStore();
const { activeName } = useNavigation();
const { isLoggedIn, fullName } = storeToRefs(authStore);

const isDark = useDark();
const toggleDark = useToggle(isDark);
const toggleTranslation = computed(() => (isDark.value ? i18n.t('navigation.toggleColorTheme.dark') : i18n.t('navigation.toggleColorTheme.light')));

const { externalLinks } = useSystemStore();

const helpUrl = 'https://google.de';

const onSelect = name => {
  switch (name) {
    case helpUrl:
      window.open(helpUrl, '_blank')?.focus();
      break;
    case 'color':
      toggleDark();
      break;
  }
};
</script>

<style lang="scss" scoped>
@use '@/assets/theme/variables';

.header-wrapper {
  min-height: variables.$topbar-height;

  .header {
    position: fixed;
    top: 0;
    z-index: 7;

    width: 100%;
    min-height: variables.$topbar-height;
    background-color: light-dark(var(--color-background-light), var(--color-background-darker));

    nav {
      display: flex;
      justify-content: space-between;
      gap: 0;

      .mock-toggle {
        margin-left: auto;
        padding: 0 10px;

        display: flex;
        align-items: center;
      }

      .menu-right {
        * {
          margin: 0;
          padding: 0;
        }

        li > *:first-child {
          height: 100%;
          padding: 10px;
        }
      }

      .menu-left,
      .menu-right {
        :deep(.btn),
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: var(--el-text-color-regular);
          font-size: 14px;
        }
      }

      .menu-right {
        display: flex;
        margin-right: 20px;

        .el-menu-item {
          border-bottom: none;

          & > span {
            height: 100%;
          }
        }

        :deep(.el-menu--horizontal > .el-sub-menu .el-sub-menu__title) {
          border-bottom: none;
        }

        :deep(.el-sub-menu.no-arrow.is-active) {
          .el-sub-menu__title {
            color: var(--color-ebony);
          }
        }
      }
    }

    .el-sub-menu.no-arrow {
      :deep(.el-sub-menu__icon-arrow) {
        display: none;
      }
    }

    .el-menu--horizontal.el-menu {
      border-bottom: none;
    }
  }
}

.external {
  display: flex;
  flex-grow: 1;
  margin: 0 -10px;
  padding: 0 10px;
  color: var(--el-menu-text-color);
  text-decoration: none;
  transition: all var(--el-transition-duration-fast);

  &:hover {
    color: var(--el-menu-hover-text-color);
    background-color: var(--el-menu-hover-bg-color);
  }
}
</style>
